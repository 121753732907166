.add-btn{
    border:none;
    color:white;
    background-color:rgb(179, 179, 243);
    font-size:1rem;
    padding:0.5rem 1rem;
}
.custom-background{
    button{
        background-color:white;
        color:black;
    }
}
.custom-div{
    width:90%;
    display:flex;
    justify-content: center;

}
.no-italics {
    font-style: normal;   
}