// Variables

//
// custom-variables
//

// Vertical Sidebar  Light
$sidebar-bg: #fbfaff;
$sidebar-menu-item-color: #545a6d;
$sidebar-menu-sub-item-color: #545a6d;
$sidebar-menu-item-icon-color: #545a6d;
$sidebar-menu-item-hover-color: #5156be;
$sidebar-menu-item-active-color: #5156be;
$sidebar-border-color: #e9e9ef;
$sidebar-width: 250px;
$sidebar-collapsed-width: 70px;
$sidebar-width-sm: 160px;

// Vertical Sidebar - Dark
$sidebar-dark-bg: #162334; //2c313a
$sidebar-dark-menu-item-color: #99a4b1;
$sidebar-dark-menu-sub-item-color: #858d98;
$sidebar-dark-menu-item-icon-color: #858d98;
$sidebar-dark-menu-item-hover-color: #ffffff;
$sidebar-dark-menu-item-active-color: #ffffff;

// Topbar - Deafult Light
$header-height: 70px;
$header-bg: #ffffff;
$header-item-color: #555b6d;

// Topbar - Dark
$header-dark-bg: #162334;
$header-dark-item-color: #e9ecef;

// Topbar Search
$topbar-search-bg: #f3f3f9;

// Footer
$footer-height: 60px;
$footer-bg: #ffffff;
$footer-color: #74788d;

// Horizontal nav
$topnav-bg: #fbfaff;
$menu-item-color: #545a6d;
$menu-item-active-color: #5156be;

// Horizontal nav
$topnav-dark-bg: #363a38;
$menu-dark-item-color: #99a4b1;
$menu-dark-item-active-color: #fff;

// Right Sidebar
$rightbar-width: 300px;

// Display
$display-none: none;
$display-block: block;

// Brand 
$navbar-brand-box-width: 250px;

// Boxed layout width
$boxed-layout-width: 1300px;
$boxed-body-bg: #f0f0f0;

// apex charts
$apex-grid-color: #f8f9fa;

// table
$table-head-bg: $gray-100;
$table-dark-border-color: tint-color($gray-800, 7.5%);


// 
// Dark theme Layout
//

$gray-dark-100: #212529;
$gray-dark-200: #162334;
$gray-dark-300: #3b403d;
$gray-dark-400: #858d98;
$gray-dark-500: #adb5bd;
$gray-dark-600: #ced4da;
$gray-dark-700: #f6f6f6;
$gray-dark-800: #e9e9ef;
$gray-dark-900: #f8f9fa;


// Variables
//
// Variables should follow the `$component-state-property-size` formula for
// consistent naming. Ex: $nav-link-disabled-color and $modal-content-box-shadow-xs.

// Color system

$white: #fff;
$gray-100: #f8f9fa;
$gray-200: #e9e9ef;
$gray-300: #f6f6f6;
$gray-400: #ced4da;
$gray-500: #adb5bd;
$gray-600: #74788d;
$gray-700: #495057;
$gray-800: #343a40;
$gray-900: #212529;
$black: #000;

// fusv-disable
$grays: (
  "100": $gray-100,
  "200": $gray-200,
  "300": $gray-300,
  "400": $gray-400,
  "500": $gray-500,
  "600": $gray-600,
  "700": $gray-700,
  "800": $gray-800,
  "900": $gray-900
);

// fusv-enable

$blue:    #7A7FDC;
$indigo:  #564ab1;
$purple:  #6f42c1;
$pink:    #e83e8c;
$red:     #fd625e;
$orange:  #f1734f;
$yellow:  #ffbf53;
$green:   #2ab57d;
$teal:    #050505;
$cyan:    #4ba6ef;
$darkblue:  #F89622;
$dark-primary: #F89622;
$light-primary: #426A9E;

// scss-docs-start colors-map
$colors: (
  "blue": $blue,
  "indigo": $indigo,
  "purple": $purple,
  "pink": $pink,
  "red": $red,
  "orange": $orange,
  "yellow": $yellow,
  "green": $green,
  "teal": $teal,
  "cyan": $cyan,
  "white": $white,
  "gray": $gray-600,
  "gray-dark": $gray-800
);
// scss-docs-end colors-map

// $primary:       $darkblue;

$secondary:     $gray-600;
$success:       $green;
$info:          $cyan;
$warning:       $yellow;
$danger:        $red;
$light:         $gray-200;
$dark:          $gray-800;

// scss-docs-start theme-colors-map
$theme-colors: (
    "light-primary":    $light-primary,
    "dark-primary":    $dark-primary,
    "secondary":  $secondary,
    "success":    $success,
    "info":       $info,
    "warning":    $warning,
    "danger":     $danger,
    "pink":       $pink,
    "light":      $light,
    "dark":       $dark
);
// scss-docs-end theme-colors-map

// The contrast ratio to reach against white, to determine if color changes from "light" to "dark". Acceptable values for WCAG 2.0 are 3, 4.5 and 7.
// See https://www.w3.org/TR/WCAG20/#visual-audio-contrast-contrast
$min-contrast-ratio: 1.8;

// Customize the light and dark text colors for use in our color contrast function.
$color-contrast-dark: $black;
$color-contrast-light: $white;

// fusv-disable
$blue-100: tint-color($blue, 80%);
$blue-200: tint-color($blue, 60%);
$blue-300: tint-color($blue, 40%);
$blue-400: tint-color($blue, 20%);
$blue-500: $blue;
$blue-600: shade-color($blue, 20%);
$blue-700: shade-color($blue, 40%);
$blue-800: shade-color($blue, 60%);
$blue-900: shade-color($blue, 80%);

$indigo-100: tint-color($indigo, 80%);
$indigo-200: tint-color($indigo, 60%);
$indigo-300: tint-color($indigo, 40%);
$indigo-400: tint-color($indigo, 20%);
$indigo-500: $indigo;
$indigo-600: shade-color($indigo, 20%);
$indigo-700: shade-color($indigo, 40%);
$indigo-800: shade-color($indigo, 60%);
$indigo-900: shade-color($indigo, 80%);

$purple-100: tint-color($purple, 80%);
$purple-200: tint-color($purple, 60%);
$purple-300: tint-color($purple, 40%);
$purple-400: tint-color($purple, 20%);
$purple-500: $purple;
$purple-600: shade-color($purple, 20%);
$purple-700: shade-color($purple, 40%);
$purple-800: shade-color($purple, 60%);
$purple-900: shade-color($purple, 80%);

$pink-100: tint-color($pink, 80%);
$pink-200: tint-color($pink, 60%);
$pink-300: tint-color($pink, 40%);
$pink-400: tint-color($pink, 20%);
$pink-500: $pink;
$pink-600: shade-color($pink, 20%);
$pink-700: shade-color($pink, 40%);
$pink-800: shade-color($pink, 60%);
$pink-900: shade-color($pink, 80%);

$red-100: tint-color($red, 80%);
$red-200: tint-color($red, 60%);
$red-300: tint-color($red, 40%);
$red-400: tint-color($red, 20%);
$red-500: $red;
$red-600: shade-color($red, 20%);
$red-700: shade-color($red, 40%);
$red-800: shade-color($red, 60%);
$red-900: shade-color($red, 80%);

$orange-100: tint-color($orange, 80%);
$orange-200: tint-color($orange, 60%);
$orange-300: tint-color($orange, 40%);
$orange-400: tint-color($orange, 20%);
$orange-500: $orange;
$orange-600: shade-color($orange, 20%);
$orange-700: shade-color($orange, 40%);
$orange-800: shade-color($orange, 60%);
$orange-900: shade-color($orange, 80%);

$yellow-100: tint-color($yellow, 80%);
$yellow-200: tint-color($yellow, 60%);
$yellow-300: tint-color($yellow, 40%);
$yellow-400: tint-color($yellow, 20%);
$yellow-500: $yellow;
$yellow-600: shade-color($yellow, 20%);
$yellow-700: shade-color($yellow, 40%);
$yellow-800: shade-color($yellow, 60%);
$yellow-900: shade-color($yellow, 80%);


$green-100: tint-color($green, 80%);
$green-200: tint-color($green, 60%);
$green-300: tint-color($green, 40%);
$green-400: tint-color($green, 20%);
$green-500: $green;
$green-600: shade-color($green, 20%);
$green-700: shade-color($green, 40%);
$green-800: shade-color($green, 60%);
$green-900: shade-color($green, 80%);


$teal-100: tint-color($teal, 80%);
$teal-200: tint-color($teal, 60%);
$teal-300: tint-color($teal, 40%);
$teal-400: tint-color($teal, 20%);
$teal-500: $teal;
$teal-600: shade-color($teal, 20%);
$teal-700: shade-color($teal, 40%);
$teal-800: shade-color($teal, 60%);
$teal-900: shade-color($teal, 80%);

$cyan-100: tint-color($cyan, 80%);
$cyan-200: tint-color($cyan, 60%);
$cyan-300: tint-color($cyan, 40%);
$cyan-400: tint-color($cyan, 20%);
$cyan-500: $cyan;
$cyan-600: shade-color($cyan, 20%);
$cyan-700: shade-color($cyan, 40%);
$cyan-800: shade-color($cyan, 60%);
$cyan-900: shade-color($cyan, 80%);
// fusv-enable

// Characters which are escaped by the escape-svg function
$escaped-characters: (
  ("<", "%3c"),
  (">", "%3e"),
  ("#", "%23"),
  ("(", "%28"),
  (")", "%29"),
);

// Options
//
// Quickly modify global styling by enabling or disabling optional features.

$enable-caret: false;
$enable-rounded: true;
$enable-shadows: false;
$enable-gradients: false;
$enable-transitions: true;
$enable-reduced-motion: true;
$enable-smooth-scroll: true;
$enable-grid-classes: true;
$enable-button-pointers: true;
$enable-rfs: true;
$enable-validation-icons: true;
$enable-negative-margins: true;
$enable-deprecation-messages: true;
$enable-important-utilities: true;


// Prefix for :root CSS variables

$variable-prefix: bs-;


// Gradient
//
// The gradient which is added to components if `$enable-gradients` is `true`
// This gradient is also added to elements with `.bg-gradient`
// scss-docs-start variable-gradient
$gradient: linear-gradient(180deg, rgba($white, .15), rgba($white, 0));
// scss-docs-end variable-gradient

// Spacing
//
// Control th styling of most Bootstrap elements by modifying these
// variables. Mostly focused on spacing.
// You can add more entries to the $spacers map, should you need more variation.

// scss-docs-start spacer-variables-maps
$spacer: 1rem;
$spacers: (
  0: 0,
  1: ($spacer * .25),
  2: ($spacer * .5),
  3: $spacer,
  4: ($spacer * 1.5),
  5: ($spacer * 3)
);

$negative-spacers: if($enable-negative-margins, negativify-map($spacers), null);
// scss-docs-end spacer-variables-maps

// Position
//
// Define the edge positioning anchors of the position utilities.

// scss-docs-start position-map
$position-values: (
  0: 0,
  50: 50%,
  100: 100%
);

// scss-docs-end position-map

// Body
//
// Settings for the `<body>` element.

$body-bg: #ffffff;
$body-color: $gray-700;
$body-text-align: null;


// Links
//
// Style anchor elements.
$light-link-color:                       $light-primary;
$dark-link-color:                       $dark-primary;
$link-color:                              $primary;
$link-decoration:                         none;
$link-shade-percentage:                   20%;
$link-hover-color:                        shift-color($link-color, $link-shade-percentage);
$link-hover-decoration:                   underline;

$stretched-link-pseudo-element:           after;
$stretched-link-z-index:                  1;



// Paragraphs
//
// Style p element.

$paragraph-margin-bottom: 1rem;


// Grid breakpoints
//
// Define the minimum dimensions at which your layout will change,
// adapting to different screen sizes, for use in media queries.

// scss-docs-start grid-breakpoints
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1400px
);
// scss-docs-end grid-breakpoints


@include _assert-ascending($grid-breakpoints, "$grid-breakpoints");
@include _assert-starts-at-zero($grid-breakpoints, "$grid-breakpoints");


// Grid containers
//
// Define the maximum width of `.container` for different screen sizes.

// scss-docs-start container-max-widths
$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
  xxl: 1320px
);
// scss-docs-end container-max-widths

@include _assert-ascending($container-max-widths, "$container-max-widths");


// Grid columns
//
// Set the number of columns and specify the width of the gutters.

$grid-columns: 12;
$grid-gutter-width: 20px;
$grid-row-columns: 6;

$gutters: $spacers;

// Container padding

$container-padding-x: calc($grid-gutter-width / 2);
// Components
//
// Define common padding and border radius sizes and more.

// scss-docs-start border-variables
$border-width: 1px;
$border-widths: (
  0: 0,
  1: 1px,
  2: 2px,
  3: 3px,
  4: 4px,
  5: 5px
);

$border-color: #e9e9ef;
// scss-docs-end border-variables

// scss-docs-start border-radius-variables
$border-radius: .25rem;
$border-radius-lg: .4rem;
$border-radius-mu: .8rem;
$border-radius-sm: .2rem;
$border-radius-pill: 50rem;
// scss-docs-end border-radius-variables

// scss-docs-start box-shadow-variables
$box-shadow-sm: 0 0rem .25rem rgba($black, .075);
$box-shadow: -0.25rem 0.25rem 0.75rem rgba(18, 38, 63, .08);
$box-shadow-lg: 0 0rem 1rem rgba($black, 0.1);
$box-shadow-inset: inset 0 1px 2px rgba($black, .075);
// scss-docs-end box-shadow-variables

$component-active-color: $white;
$component-active-bg: $primary;

// scss-docs-start caret-variables
$caret-width: .3em;
$caret-vertical-align: $caret-width * .85;
$caret-spacing: $caret-width * .85;
// scss-docs-end caret-variables

$transition-base: all .2s ease-in-out;
$transition-fade: opacity .15s linear;
// scss-docs-start collapse-transition
$transition-collapse: height .35s ease;
// scss-docs-end collapse-transition

// stylelint-disable function-disallowed-list
// scss-docs-start aspect-ratios
$aspect-ratios: (
  "1x1": 100%,
  "4x3": calc(3 / 4 * 100%),
  "16x9": calc(9 / 16 * 100%),
  "21x9": calc(9 / 21 * 100%)
);
// scss-docs-end aspect-ratios
// stylelint-enable function-disallowed-list

// Typography
//
// Font, line-height, and color for body text, headings, and more.

// scss-docs-start font-variables
// stylelint-disable value-keyword-case
$font-family-sans-serif: 'IBM Plex Sans', sans-serif;
$font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;

// stylelint-enable value-keyword-case
$font-family-base: var(--#{$variable-prefix}font-sans-serif);
$font-family-code: var(--#{$variable-prefix}font-monospace);

// $font-size-root effects the value of `rem`, which is used for as well font sizes, paddings and margins
// $font-size-base effects the font size of the body text
$font-size-root: null;
$font-size-base: 0.875rem;
$font-size-sm: $font-size-base * .875;
$font-size-lg: $font-size-base * 1.25;


$font-weight-lighter: lighter;
$font-weight-light: 300;
$font-weight-normal: 400;
$font-weight-medium: 500;
$font-weight-semibold: 600;
$font-weight-bold: 700;
$font-weight-bolder: bolder;

$font-weight-base: $font-weight-normal;
$line-height-base: 1.5;
$line-height-sm: 1.25;
$line-height-lg: 2;

$h1-font-size: $font-size-base * 2.5;
$h2-font-size: $font-size-base * 2;
$h3-font-size: $font-size-base * 1.75;
$h4-font-size: $font-size-base * 1.5;
$h5-font-size: $font-size-base * 1.25;
$h6-font-size: $font-size-base;
// scss-docs-end font-variables

// scss-docs-start font-sizes
$font-sizes: (
  1: $h1-font-size,
  2: $h2-font-size,
  3: $h3-font-size,
  4: $h4-font-size,
  5: $h5-font-size,
  6: $h6-font-size,
  7: $font-size-base * 0.85,
  8: $font-size-base * 0.75,
);
// scss-docs-end font-sizes

// scss-docs-start headings-variables
$headings-margin-bottom: calc($spacer / 2);
$headings-font-family: null;
$headings-font-style: null;
$headings-font-weight: 600;
$headings-line-height: 1.2;
$headings-color: null;
// scss-docs-end headings-variables

// scss-docs-start display-headings
$display-font-sizes: (
  1: 6rem,
  2: 5.5rem,
  3: 4.5rem,
  4: 3.5rem,
  5: 3rem,
  6: 2.5rem
);

$display-font-weight: 300;
$display-line-height: $headings-line-height;
// scss-docs-end display-headings

// scss-docs-start type-variables
$lead-font-size: $font-size-base * 1.25;
$lead-font-weight: 300;

$small-font-size: 80%;

$sub-sup-font-size: .75em;
$text-muted: $gray-600;

$initialism-font-size: $small-font-size;

$blockquote-margin-y: $spacer;
$blockquote-font-size: $font-size-base * 1.25;
$blockquote-footer-color: $gray-600;
$blockquote-footer-font-size: $small-font-size;

$hr-margin-y: $spacer;
$hr-color: inherit;
$hr-height: $border-width;
$hr-opacity: .2;

$legend-margin-bottom: .5rem;
$legend-font-size: 1.5rem;
$legend-font-weight: null;

$mark-padding: .2em;

$dt-font-weight: $font-weight-bold;

$nested-kbd-font-weight: $font-weight-bold;

$list-inline-padding: .5rem;

$mark-bg: #fcf8e3;
// scss-docs-end type-variables


// Tables
//
// Customizes the `.table` component with basic values, each used across all table variations.

// scss-docs-start table-variables
$table-cell-padding-y: .75rem;
$table-cell-padding-x: .75rem;
$table-cell-padding-y-sm: .3rem;
$table-cell-padding-x-sm: .3rem;

$table-cell-vertical-align: top;

$table-color: $body-color;
$table-bg: null;

$table-th-font-weight: null;


$table-striped-color: $table-color;
$table-striped-bg-factor: .05;
$table-striped-bg: $gray-100;

$table-active-color: $table-color;
$table-active-bg-factor: .1;
$table-active-bg: $gray-100;

$table-hover-color: $table-color;
$table-hover-bg-factor: .075;
$table-hover-bg: $gray-100;

$table-border-factor: .1;
$table-border-width: $border-width;
$table-border-color: $border-color;

$table-striped-order: odd;
$table-group-separator-color: $border-color;

$table-caption-color: $text-muted;

$table-bg-scale: -80%;
// scss-docs-end table-variables

// scss-docs-start table-loop
$table-variants: (
  "primary":    shift-color($primary, $table-bg-scale),
  "light-primary":    shift-color($light-primary, $table-bg-scale),
  "dark-primary":    shift-color($dark-primary, $table-bg-scale),
  "secondary":  shift-color($secondary, $table-bg-scale),
  "success":    shift-color($success, $table-bg-scale),
  "info":       shift-color($info, $table-bg-scale),
  "warning":    shift-color($warning, $table-bg-scale),
  "danger":     shift-color($danger, $table-bg-scale),
  "light":      $light,
  "dark":       $dark,
);
// scss-docs-end table-loop


// Buttons + Forms
//
// Shared variables that are reassigned to `$input-` and `$btn-` specific variables.

// scss-docs-start input-btn-variables
$input-btn-padding-y: .47rem;
$input-btn-padding-x: .75rem;
$input-btn-font-family: null;
$input-btn-font-size: $font-size-base;
$input-btn-line-height: $line-height-base;

$input-btn-focus-width: .15rem;
$input-btn-focus-color-opacity: .25;
$input-btn-focus-color: rgba($component-active-bg, $input-btn-focus-color-opacity);
$input-btn-focus-blur: 0;
$input-btn-focus-box-shadow: 0 0 0 $input-btn-focus-width $input-btn-focus-color;

$input-btn-padding-y-sm: .25rem;
$input-btn-padding-x-sm: .5rem;
$input-btn-font-size-sm: $font-size-sm;

$input-btn-padding-y-lg: .5rem;
$input-btn-padding-x-lg: 1rem;
$input-btn-font-size-lg: $font-size-lg;

$input-btn-border-width: $border-width;
// scss-docs-end input-btn-variables


// Buttons
//
// For each of Bootstrap's buttons, define text, background, and border color.

// scss-docs-start btn-variables
$btn-padding-y: $input-btn-padding-y;
$btn-padding-x: $input-btn-padding-x;
$btn-font-family: $input-btn-font-family;
$btn-font-size: $input-btn-font-size;
$btn-line-height: $input-btn-line-height;
$btn-white-space: null; // Set to `nowrap` to prevent text wrapping

$btn-padding-y-sm: $input-btn-padding-y-sm;
$btn-padding-x-sm: $input-btn-padding-x-sm;
$btn-font-size-sm: $input-btn-font-size-sm;

$btn-padding-y-lg: $input-btn-padding-y-lg;
$btn-padding-x-lg: $input-btn-padding-x-lg;
$btn-font-size-lg: $input-btn-font-size-lg;

$btn-border-width: $input-btn-border-width;

$btn-font-weight: $font-weight-normal;
$btn-box-shadow: 0 2px 6px 0 rgba($black, .075);
$btn-focus-width: $input-btn-focus-width;
$btn-focus-box-shadow: $input-btn-focus-box-shadow;
$btn-disabled-opacity: .65;
$btn-active-box-shadow: inset 0 3px 5px rgba($black, .125);

$btn-link-color: $link-color;
$btn-link-hover-color: $link-hover-color;
$btn-link-disabled-color: $gray-600;

// Allows for customizing button radius independently from global border radius
$btn-border-radius: $border-radius;
$btn-border-radius-sm: $border-radius-sm;
$btn-border-radius-lg: $border-radius-lg;

$btn-transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;

$btn-hover-bg-shade-amount: 15%;
$btn-hover-bg-tint-amount: 15%;
$btn-hover-border-shade-amount: 20%;
$btn-hover-border-tint-amount: 10%;
$btn-active-bg-shade-amount: 20%;
$btn-active-bg-tint-amount: 20%;
$btn-active-border-shade-amount: 25%;
$btn-active-border-tint-amount: 10%;
// scss-docs-end btn-variables


// Forms

// scss-docs-start form-text-variables
$form-text-margin-top: .25rem;
$form-text-font-size: $small-font-size;
$form-text-font-style: null;
$form-text-font-weight: null;
$form-text-color: $text-muted;
// scss-docs-end form-text-variables

// scss-docs-start form-label-variables
$form-label-margin-bottom: .5rem;
$form-label-font-size: null;
$form-label-font-style: null;
$form-label-font-weight: null;
$form-label-color: null;
// scss-docs-end form-label-variables

// scss-docs-start form-input-variables
$input-padding-y: $input-btn-padding-y;
$input-padding-x: $input-btn-padding-x;
$input-font-family: $input-btn-font-family;
$input-font-size: $input-btn-font-size;
$input-font-weight: $font-weight-base;
$input-line-height: $input-btn-line-height;

$input-padding-y-sm: $input-btn-padding-y-sm;
$input-padding-x-sm: $input-btn-padding-x-sm;
$input-font-size-sm: $input-btn-font-size-sm;

$input-padding-y-lg: $input-btn-padding-y-lg;
$input-padding-x-lg: $input-btn-padding-x-lg;
$input-font-size-lg: $input-btn-font-size-lg;

$input-bg: $white;
$input-disabled-bg: $gray-200;
$input-disabled-border-color: null;

$input-color: $gray-700;
$input-border-color: $gray-400;
$input-border-width: $input-btn-border-width;
$input-box-shadow: $box-shadow-inset;

$input-border-radius: $border-radius;
$input-border-radius-lg: $border-radius-lg;
$input-border-radius-sm: $border-radius-sm;

$input-focus-bg: $input-bg;
$input-focus-border-color: shade-color($input-border-color, 10%);
$input-focus-color: $input-color;
$input-focus-width: $input-btn-focus-width;
$input-focus-box-shadow: none;

$input-placeholder-color: $gray-500;
$input-plaintext-color: $body-color;

$input-height-border: $input-border-width * 2;

$input-height-inner: add($input-line-height * 1em, $input-padding-y * 2);
$input-height-inner-half: add($input-line-height * .5em, $input-padding-y);
$input-height-inner-quarter: add($input-line-height * .25em, calc($input-padding-y / 2));

$input-height: add($input-line-height * 1em, add($input-padding-y * 2, $input-height-border, false));
$input-height-sm: add($input-line-height * 1em, add($input-padding-y-sm * 2, $input-height-border, false));
$input-height-lg: add($input-line-height * 1em, add($input-padding-y-lg * 2, $input-height-border, false));

$input-transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
// scss-docs-end form-input-variables

// scss-docs-start form-check-variables
$form-check-input-width: 1em;
$form-check-min-height: $font-size-base * $line-height-base;
$form-check-padding-start: $form-check-input-width + .5em;
$form-check-margin-bottom: .125rem;
$form-check-label-color: null;
$form-check-label-cursor: null;
$form-check-transition: background-color .15s ease-in-out, background-position .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;

$form-check-input-active-filter: brightness(90%);

$form-check-input-bg: $card-bg;
$form-check-input-border: 1px solid rgba(0, 0, 0, .25);
$form-check-input-border-radius: .25em;
$form-check-radio-border-radius: 50%;
$form-check-input-focus-border: $input-focus-border-color;
$form-check-input-focus-box-shadow: $input-btn-focus-box-shadow;

$form-check-input-checked-color: $component-active-color;
$form-check-input-checked-bg-color: $component-active-bg;
$form-check-input-checked-border-color: $form-check-input-checked-bg-color;
$form-check-input-checked-bg-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'><path fill='none' stroke='#{$form-check-input-checked-color}' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/></svg>");
$form-check-radio-checked-bg-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'><circle r='2' fill='#{$form-check-input-checked-color}'/></svg>");

$form-check-input-indeterminate-color: $component-active-color;
$form-check-input-indeterminate-bg-color: $component-active-bg;
$form-check-input-indeterminate-border-color: $form-check-input-indeterminate-bg-color;
$form-check-input-indeterminate-bg-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'><path fill='none' stroke='#{$form-check-input-indeterminate-color}' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10h8'/></svg>");

$form-check-input-disabled-opacity: .5;
$form-check-label-disabled-opacity: $form-check-input-disabled-opacity;
$form-check-btn-check-disabled-opacity: $btn-disabled-opacity;


$form-check-inline-margin-end: 1rem;
// scss-docs-end form-check-variables

// scss-docs-start form-switch-variables
$form-switch-color: rgba(0, 0, 0, .25);
$form-switch-width: 2em;
$form-switch-padding-start: $form-switch-width + .5em;
$form-switch-bg-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'><circle r='3' fill='#{$form-switch-color}'/></svg>");
$form-switch-border-radius: $form-switch-width;
$form-switch-transition: background-position .15s ease-in-out;

$form-switch-focus-color: $input-focus-border-color;
$form-switch-focus-bg-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'><circle r='3' fill='#{$form-switch-focus-color}'/></svg>");

$form-switch-checked-color: $component-active-color;
$form-switch-checked-bg-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'><circle r='3' fill='#{$form-switch-checked-color}'/></svg>");
$form-switch-checked-bg-position: right center;
// scss-docs-end form-switch-variables
$form-check-inline-margin-end: 1rem;
// scss-docs-start input-group-variables
$input-group-addon-padding-y: $input-padding-y;
$input-group-addon-padding-x: $input-padding-x;
$input-group-addon-font-weight: $input-font-weight;
$input-group-addon-color: $input-color;
$input-group-addon-bg: $gray-200;
$input-group-addon-border-color: $input-border-color;
// scss-docs-end input-group-variables

// scss-docs-start form-select-variables
$form-select-padding-y: $input-padding-y;
$form-select-padding-x: $input-padding-x;
$form-select-font-family: $input-font-family;
$form-select-font-size: $input-font-size;
$form-select-indicator-padding: 1.75rem; // Extra padding to account for the presence of the background-image based indicator
$form-select-font-weight: $input-font-weight;
$form-select-line-height: $input-line-height;
$form-select-color: $input-color;
$form-select-disabled-color: $gray-600;
$form-select-bg: $input-bg;
$form-select-disabled-bg: $gray-200;
$form-select-disabled-border-color: $input-disabled-border-color;
$form-select-bg-position: right $form-select-padding-x center;
$form-select-bg-size: 16px 12px; // In pixels because image dimensions
$form-select-indicator-color: $gray-800;
$form-select-indicator: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'><path fill='none' stroke='#{$form-select-indicator-color}' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/></svg>");

$form-select-feedback-icon-padding-end: add(1em * .75, (2 * $form-select-padding-y * .75) + $form-select-padding-x + $form-select-indicator-padding);
$form-select-feedback-icon-position: center right ($form-select-padding-x + $form-select-indicator-padding);
$form-select-feedback-icon-size: $input-height-inner-half $input-height-inner-half;

$form-select-border-width: $input-border-width;
$form-select-border-color: $input-border-color;
$form-select-border-radius: $border-radius;
$form-select-box-shadow: $box-shadow-inset;

$form-select-focus-border-color: $input-focus-border-color;
$form-select-focus-width: $input-focus-width;
$form-select-focus-box-shadow: 0 0 0 $form-select-focus-width $input-btn-focus-color;

$form-select-padding-y-sm: $input-padding-y-sm;
$form-select-padding-x-sm: $input-padding-x-sm;
$form-select-font-size-sm: $input-font-size-sm;

$form-select-padding-y-lg: $input-padding-y-lg;
$form-select-padding-x-lg: $input-padding-x-lg;
$form-select-font-size-lg: $input-font-size-lg;
// scss-docs-end form-select-variables

// scss-docs-start form-range-variables
$form-range-track-width: 100%;
$form-range-track-height: .5rem;
$form-range-track-cursor: pointer;
$form-range-track-bg: $gray-300;
$form-range-track-border-radius: 1rem;
$form-range-track-box-shadow: $box-shadow-inset;

$form-range-thumb-width: 1rem;
$form-range-thumb-height: $form-range-thumb-width;
$form-range-thumb-bg: $component-active-bg;
$form-range-thumb-border: 0;
$form-range-thumb-border-radius: 1rem;
$form-range-thumb-box-shadow: 0 .1rem .25rem rgba($black, .1);
$form-range-thumb-focus-box-shadow: 0 0 0 1px $body-bg, $input-focus-box-shadow;
$form-range-thumb-focus-box-shadow-width: $input-focus-width; // For focus box shadow issue in Edge
$form-range-thumb-active-bg: tint-color($component-active-bg, 70%);
$form-range-thumb-disabled-bg: $gray-500;
$form-range-thumb-transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
// scss-docs-end form-range-variables

// scss-docs-start form-file-variables
$form-file-button-color: $input-color;
$form-file-button-bg: $input-group-addon-bg;
$form-file-button-hover-bg: shade-color($form-file-button-bg, 5%);
// scss-docs-end form-file-variables

// scss-docs-start form-floating-variables
$form-floating-height: add(3.5rem, $input-height-border);
$form-floating-padding-x: $input-padding-x;
$form-floating-padding-y: 1rem;
$form-floating-input-padding-t: 1.625rem;
$form-floating-input-padding-b: .625rem;
$form-floating-label-opacity: .65;
$form-floating-label-transform: scale(.85) translateY(-.5rem) translateX(.15rem);
$form-floating-transition: opacity .1s ease-in-out, transform .1s ease-in-out;
// scss-docs-end form-floating-variables

// Form validation

// scss-docs-start form-feedback-variables
$form-feedback-margin-top: $form-text-margin-top;
$form-feedback-font-size: $form-text-font-size;
$form-feedback-font-style: $form-text-font-style;
$form-feedback-valid-color: $success;
$form-feedback-invalid-color: $danger;

$form-feedback-icon-valid-color: $form-feedback-valid-color;
$form-feedback-icon-valid: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'><path fill='#{$form-feedback-icon-valid-color}' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/></svg>");
$form-feedback-icon-invalid-color: $form-feedback-invalid-color;
$form-feedback-icon-invalid: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='#{$form-feedback-icon-invalid-color}'><circle cx='6' cy='6' r='4.5'/><path stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/><circle cx='6' cy='8.2' r='.6' fill='#{$form-feedback-icon-invalid-color}' stroke='none'/></svg>");
// scss-docs-end form-feedback-variables

// scss-docs-start form-validation-states
$form-validation-states: (
  "valid": ("color": $form-feedback-valid-color,
    "icon": $form-feedback-icon-valid ),
  "invalid": ("color": $form-feedback-invalid-color,
    "icon": $form-feedback-icon-invalid )
);
// scss-docs-end form-validation-states

// Z-index master list
//
// Warning: Avoid customizing these values. They're used for a bird's eye view
// of components dependent on the z-axis and are designed to all work together.

// scss-docs-start zindex-stack
$zindex-dropdown: 1000;
$zindex-sticky: 1020;
$zindex-fixed: 1030;
$zindex-modal-backdrop: 1040;
$zindex-offcanvas: 1050;
$zindex-modal: 1060;
$zindex-popover: 1070;
$zindex-tooltip: 1080;
// scss-docs-end zindex-stack


// Navs

// scss-docs-start nav-variables
$nav-link-padding-y: .5rem;
$nav-link-padding-x: 1rem;
$nav-link-font-size: null;
$nav-link-font-weight: null;
$nav-link-color: $body-color;
$nav-link-hover-color: null;
$nav-link-transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out;
$nav-link-disabled-color: $gray-600;

$nav-tabs-border-color: $gray-400;
$nav-tabs-border-width: $border-width;
$nav-tabs-border-radius: $border-radius;
$nav-tabs-link-hover-border-color: $gray-200 $gray-200 $nav-tabs-border-color;
$nav-tabs-link-active-color: $gray-700;
$nav-tabs-link-active-bg: $card-bg;
$nav-tabs-link-active-border-color: $gray-400 $gray-400 $nav-tabs-link-active-bg;

$nav-pills-border-radius: $border-radius;
$nav-pills-link-active-color: $component-active-color;
$nav-pills-link-active-bg: $component-active-bg;
// scss-docs-end nav-variables


// Navbar

// scss-docs-start navbar-variables
$navbar-padding-y: calc($spacer / 2);
$navbar-padding-x: null;

$navbar-nav-link-padding-x: .5rem;

$navbar-brand-font-size: $font-size-lg;
// Compute the navbar-brand padding-y so the navbar-brand will have the same height as navbar-text and nav-link
$nav-link-height: $font-size-base * $line-height-base + $nav-link-padding-y * 2;
$navbar-brand-height: $navbar-brand-font-size * $line-height-base;
$navbar-brand-padding-y: calc(($nav-link-height - $navbar-brand-height) / 2);
$navbar-brand-margin-end: 1rem;

$navbar-toggler-padding-y: .25rem;
$navbar-toggler-padding-x: .75rem;
$navbar-toggler-font-size: $font-size-lg;
$navbar-toggler-border-radius: $btn-border-radius;
$navbar-toggler-focus-width: $btn-focus-width;
$navbar-toggler-transition: box-shadow .15s ease-in-out;
// scss-docs-end navbar-variables

// scss-docs-start navbar-theme-variables
$navbar-dark-color: rgba($white, .55);
$navbar-dark-hover-color: rgba($white, .75);
$navbar-dark-active-color: $white;
$navbar-dark-disabled-color: rgba($white, .25);
$navbar-dark-toggler-icon-bg: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'><path stroke='#{$navbar-dark-color}' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/></svg>");
$navbar-dark-toggler-border-color: rgba($white, .1);

$navbar-light-color: rgba($black, .55);
$navbar-light-hover-color: rgba($black, .7);
$navbar-light-active-color: rgba($black, .9);
$navbar-light-disabled-color: rgba($black, .3);
$navbar-light-toggler-icon-bg: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'><path stroke='#{$navbar-light-color}' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/></svg>");
$navbar-light-toggler-border-color: rgba($black, .1);

$navbar-light-brand-color: $navbar-light-active-color;
$navbar-light-brand-hover-color: $navbar-light-active-color;
$navbar-dark-brand-color: $navbar-dark-active-color;
$navbar-dark-brand-hover-color: $navbar-dark-active-color;
// scss-docs-end navbar-theme-variables


// Dropdowns
//
// Dropdown menu container and contents.

// scss-docs-start dropdown-variables
$dropdown-min-width: 10rem;
$dropdown-padding-x: 0;
$dropdown-padding-y: .5rem;
$dropdown-spacer: .125rem;
$dropdown-font-size: $font-size-base;
$dropdown-color: $body-color;
$dropdown-bg: $white;
$dropdown-border-color: $gray-200;
$dropdown-border-radius: $border-radius;
$dropdown-border-width: 1px;
$dropdown-inner-border-radius: calc(#{$dropdown-border-radius} - #{$dropdown-border-width});
$dropdown-divider-bg: $gray-200;
$dropdown-divider-margin-y: calc($spacer / 2);
$dropdown-box-shadow: $box-shadow;

$dropdown-link-color: $gray-700;
$dropdown-link-hover-color: $gray-800;
$dropdown-link-hover-bg: $gray-100;

$dropdown-link-active-color: $dark;
$dropdown-link-active-bg: $gray-100;

$dropdown-link-disabled-color: $gray-600;

$dropdown-item-padding-y: .35rem;
$dropdown-item-padding-x: 1rem;

$dropdown-header-color: $gray-600;
$dropdown-header-padding: $dropdown-padding-y $dropdown-item-padding-x;
// scss-docs-end dropdown-variables

// scss-docs-start dropdown-dark-variables
$dropdown-dark-color: $gray-300;
$dropdown-dark-bg: $gray-800;
$dropdown-dark-border-color: $dropdown-border-color;
$dropdown-dark-divider-bg: $dropdown-divider-bg;
$dropdown-dark-box-shadow: null;
$dropdown-dark-link-color: $dropdown-dark-color;
$dropdown-dark-link-hover-color: $white;
$dropdown-dark-link-hover-bg: rgba($white, .15);
$dropdown-dark-link-active-color: $dropdown-link-active-color;
$dropdown-dark-link-active-bg: $dropdown-link-active-bg;
$dropdown-dark-link-disabled-color: $gray-500;
$dropdown-dark-header-color: $gray-500;
// scss-docs-end dropdown-dark-variables


// Pagination

// scss-docs-start pagination-variables
$pagination-padding-y: .5rem;
$pagination-padding-x: .75rem;
$pagination-padding-y-sm: .25rem;
$pagination-padding-x-sm: .5rem;
$pagination-padding-y-lg: .75rem;
$pagination-padding-x-lg: 1.5rem;

$pagination-color: $gray-600;
$pagination-bg: $white;
$pagination-border-width: $border-width;
$pagination-border-radius: $border-radius;
$pagination-margin-start: -$pagination-border-width;
$pagination-border-color: $gray-400;

$pagination-focus-color: $link-hover-color;
$pagination-focus-bg: $gray-200;
$pagination-focus-box-shadow: $input-btn-focus-box-shadow;
$pagination-focus-outline: 0;

$pagination-hover-color: $link-hover-color;
$pagination-hover-bg: $gray-200;
$pagination-hover-border-color: $gray-400;

$pagination-active-color: $component-active-color;
$pagination-active-bg: $component-active-bg;
$pagination-active-border-color: $pagination-active-bg;

$pagination-disabled-color: $gray-400;
$pagination-disabled-bg: $white;
$pagination-disabled-border-color: $gray-400;

$pagination-transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;

$pagination-border-radius-sm: $border-radius-sm;
$pagination-border-radius-lg: $border-radius-lg;
// scss-docs-end pagination-variables


// Cards

// scss-docs-start card-variables
$card-spacer-y: 1.25rem;
$card-spacer-x: 1.25rem;
$card-title-spacer-y: calc($spacer / 2);
$card-border-width: 1px;
$card-border-radius: $border-radius;
$card-border-color: $gray-200;
$card-inner-border-radius: calc(#{$card-border-radius} - #{$card-border-width});
$card-cap-padding-y: $card-spacer-y;
$card-cap-padding-x: $card-spacer-x;
$card-cap-bg: $white;
$card-cap-color: null;
$card-height: null;
$card-color: null;
$card-bg: $white;


$card-title-desc: $gray-600;

$card-group-margin: calc($grid-gutter-width / 2);
// scss-docs-end card-variables

// Accordion

// scss-docs-start accordion-variables
$accordion-padding-y: 1rem;
$accordion-padding-x: 1.25rem;
$accordion-color: $body-color;
$accordion-bg: transparent;
$accordion-border-width: $border-width;
$accordion-border-color: rgba($black, .125);
$accordion-border-radius: $border-radius;
$accordion-inner-border-radius: subtract($accordion-border-radius, $accordion-border-width);

$accordion-body-padding-y: $accordion-padding-y;
$accordion-body-padding-x: $accordion-padding-x;

$accordion-button-padding-y: $accordion-padding-y;
$accordion-button-padding-x: $accordion-padding-x;
$accordion-button-color: $accordion-color;
$accordion-button-bg: $accordion-bg;
$accordion-transition: $btn-transition, border-radius .15s ease;
$accordion-button-active-bg: tint-color($component-active-bg, 90%);
$accordion-button-active-color: shade-color($primary, 10%);

$accordion-button-focus-border-color: $input-focus-border-color;
$accordion-button-focus-box-shadow: none;

$accordion-icon-width: 16px;
$accordion-icon-color: $accordion-color;
$accordion-icon-active-color: $accordion-button-active-color;
$accordion-icon-transition: transform .2s ease-in-out;
$accordion-icon-transform: rotate(180deg);

$accordion-button-icon: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='#{$accordion-icon-color}'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>");
$accordion-button-active-icon: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='#{$accordion-icon-active-color}'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>");
// scss-docs-end accordion-variables

// Tooltips

// scss-docs-start tooltip-variables
$tooltip-font-size: $font-size-sm;
$tooltip-max-width: 200px;
$tooltip-color: $white;
$tooltip-bg: $black;
$tooltip-border-radius: $border-radius;
$tooltip-opacity: .9;
$tooltip-padding-y: .4rem;
$tooltip-padding-x: .7rem;
$tooltip-margin: 0;

$tooltip-arrow-width: .8rem;
$tooltip-arrow-height: .4rem;
$tooltip-arrow-color: $tooltip-bg;
// scss-docs-end tooltip-variables

// Form tooltips must come after regular tooltips
// scss-docs-start tooltip-feedback-variables
$form-feedback-tooltip-padding-y: $tooltip-padding-y;
$form-feedback-tooltip-padding-x: $tooltip-padding-x;
$form-feedback-tooltip-font-size: $tooltip-font-size;
$form-feedback-tooltip-line-height: $line-height-base;
$form-feedback-tooltip-opacity: $tooltip-opacity;
$form-feedback-tooltip-border-radius: $tooltip-border-radius;
// scss-docs-start tooltip-feedback-variables


// Popovers

// scss-docs-start popover-variables
$popover-font-size: $font-size-sm;
$popover-bg: $white;
$popover-max-width: 276px;
$popover-border-width: $border-width;
$popover-border-color: $gray-200;
$popover-border-radius: $border-radius-lg;
$popover-inner-border-radius: subtract($popover-border-radius, $popover-border-width);
$popover-box-shadow: 0 .25rem .5rem rgba($black, .2);

$popover-header-bg: shade-color($popover-bg, 4%);
$popover-header-color: $headings-color;
$popover-header-padding-y: .5rem;
$popover-header-padding-x: $spacer;

$popover-body-color: $body-color;
$popover-body-padding-y: $spacer;
$popover-body-padding-x: $spacer;

$popover-arrow-width: 1rem;
$popover-arrow-height: .5rem;
$popover-arrow-color: $popover-bg;

$popover-arrow-outer-color: fade-in($popover-border-color, .05);
// scss-docs-end popover-variables


// Toasts

// scss-docs-start toast-variables
$toast-max-width: 350px;
$toast-padding-x: .75rem;
$toast-padding-y: .25rem;
$toast-font-size: .875rem;
$toast-color: null;
$toast-background-color: rgba($white, .85);
$toast-border-width: 1px;
$toast-border-color: rgba(0, 0, 0, .1);
$toast-border-radius: .25rem;
$toast-box-shadow: $box-shadow;
$toast-spacing: $container-padding-x;

$toast-header-color: $gray-600;
$toast-header-background-color: rgba($white, .85);
$toast-header-border-color: rgba(0, 0, 0, .05);
// scss-docs-end toast-variables


// Badges

// scss-docs-start badge-variables
$badge-font-size: 75%;
$badge-font-weight: $font-weight-medium;
$badge-color: $white;
$badge-padding-y: .25em;
$badge-padding-x: .4em;
$badge-border-radius: $border-radius;
// scss-docs-end badge-variables


// Modals

// scss-docs-start modal-variables
$modal-inner-padding: 1rem;

// Margin between elements in footer, must be lower than or equal to 2 * $modal-inner-padding
$modal-footer-margin-between: .5rem;
$modal-dialog-margin: .5rem;
$modal-dialog-margin-y-sm-up: 1.75rem;

$modal-title-line-height: $line-height-base;

$modal-content-color: null;
$modal-content-bg: $white;
$modal-content-border-color: $gray-300;
$modal-content-border-width: $border-width;
$modal-content-border-radius: $border-radius;
$modal-content-inner-border-radius: subtract($modal-content-border-radius, $modal-content-border-width);
$modal-content-box-shadow-xs: $box-shadow-sm;
$modal-content-box-shadow-sm-up: $box-shadow;


$modal-backdrop-bg: $black;
$modal-backdrop-opacity: .5;
$modal-header-border-color: $border-color;
$modal-footer-border-color: $modal-header-border-color;
$modal-header-border-width: $modal-content-border-width;
$modal-footer-border-width: $modal-header-border-width;
$modal-header-padding-y: $modal-inner-padding;
$modal-header-padding-x: $modal-inner-padding;
$modal-header-padding: $modal-header-padding-y $modal-header-padding-x; // Keep this for backwards compatibility

$modal-sm: 300px;
$modal-md: 500px;
$modal-lg: 800px;
$modal-xl: 1140px;

$modal-fade-transform: translate(0, -50px);
$modal-show-transform: none;
$modal-transition: transform .3s ease-out;
$modal-scale-transform: scale(1.02);
// scss-docs-end modal-variables


// Alerts
//
// Define alert colors, border radius, and padding.

// scss-docs-start alert-variables
$alert-padding-y: .75rem;
$alert-padding-x: 1.25rem;
$alert-margin-bottom: 1rem;
$alert-border-radius: $border-radius;
$alert-link-font-weight: $font-weight-medium;
$alert-border-width: $border-width * 2;

$alert-bg-scale: -60%;
$alert-border-scale: -50%;
$alert-color-scale: 30%;

$alert-dismissible-padding-r: $alert-padding-x * 3; // 3x covers width of x plu padding on either side
// scss-docs-end alert-variables


// Progress bars

// scss-docs-start progress-variables
$progress-height: .625rem;
$progress-font-size: $font-size-base * .75;
$progress-bg: $gray-300;
$progress-border-radius: $border-radius;
$progress-box-shadow: $box-shadow-inset;
$progress-bar-color: $black;
$progress-bar-bg: $primary;
$progress-bar-animation-timing: 1s linear infinite;
$progress-bar-transition: width .6s ease;
// scss-docs-end progress-variables


// List group

// scss-docs-start list-group-variables
$list-group-color: null;
$list-group-bg: $white;
$list-group-border-color: $border-color;
$list-group-border-width: $border-width;
$list-group-border-radius: $border-radius;

$list-group-item-padding-y: .75rem;
$list-group-item-padding-x: 1.25rem;
$list-group-item-bg-scale: -80%;
$list-group-item-color-scale: 40%;

$list-group-hover-bg: $gray-100;
$list-group-active-color: $component-active-color;
$list-group-active-bg: $component-active-bg;
$list-group-active-border-color: $list-group-active-bg;

$list-group-disabled-color: $gray-600;
$list-group-disabled-bg: $list-group-bg;

$list-group-action-color: $gray-700;
$list-group-action-hover-color: $list-group-action-color;

$list-group-action-active-color: $body-color;
$list-group-action-active-bg: $gray-200;
// scss-docs-end list-group-variables


// Image thumbnails

// scss-docs-start thumbnail-variables
$thumbnail-padding: .25rem;
$thumbnail-bg: $body-bg;
$thumbnail-border-width: $border-width;
$thumbnail-border-color: $gray-400;
$thumbnail-border-radius: $border-radius;
$thumbnail-box-shadow: $box-shadow-sm;
// scss-docs-end thumbnail-variables


// Figures

// scss-docs-start figure-variables
$figure-caption-font-size: $small-font-size;
$figure-caption-color: $gray-600;
// scss-docs-end figure-variables


// Breadcrumbs

// scss-docs-start breadcrumb-variables
$breadcrumb-font-size: null;
$breadcrumb-padding-y: .75rem;
$breadcrumb-padding-x: 1rem;
$breadcrumb-item-padding: .5rem;

$breadcrumb-margin-bottom: 1rem;

$breadcrumb-bg: null;
$breadcrumb-divider-color: $gray-600;
$breadcrumb-active-color: $gray-600;
$breadcrumb-divider: quote("\F0142");
$breadcrumb-divider-flipped: $breadcrumb-divider;
$breadcrumb-border-radius: null;
// scss-docs-end breadcrumb-variables


// Carousel

// scss-docs-start carousel-variables
$carousel-control-color: $white;
$carousel-control-width: 15%;
$carousel-control-opacity: .5;
$carousel-control-hover-opacity: .9;
$carousel-control-transition: opacity .15s ease;

$carousel-indicator-width: 30px;
$carousel-indicator-height: 3px;
$carousel-indicator-hit-area-height: 10px;
$carousel-indicator-spacer: 3px;
$carousel-indicator-opacity: .5;
$carousel-indicator-active-bg: $white;
$carousel-indicator-active-opacity: 1;
$carousel-indicator-transition: opacity .6s ease;

$carousel-caption-width: 70%;
$carousel-caption-color: $white;
$carousel-caption-padding-y: 1.25rem;
$carousel-caption-spacer: 1.25rem;

$carousel-control-icon-width: 2rem;

$carousel-control-prev-icon-bg: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='#{$carousel-control-color}'><path d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/></svg>");
$carousel-control-next-icon-bg: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='#{$carousel-control-color}'><path d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/></svg>");

$carousel-transition-duration: .6s;
$carousel-transition: transform $carousel-transition-duration ease-in-out; // Define transform transition first if using multiple transitions (e.g., `transform 2s ease, opacity .5s ease-out`)

$carousel-dark-indicator-active-bg: $black;
$carousel-dark-caption-color: $black;
$carousel-dark-control-icon-filter: invert(1) grayscale(100);
// scss-docs-end carousel-variables


// Spinners

// scss-docs-start spinner-variables
$spinner-width: 2rem;
$spinner-height: $spinner-width;
$spinner-border-width: .25em;
$spinner-animation-speed: .75s;

$spinner-width-sm: 1rem;
$spinner-height-sm: $spinner-width-sm;
$spinner-border-width-sm: .2em;
// scss-docs-end spinner-variables


// Close

// scss-docs-start close-variables
$btn-close-width: 1em;
$btn-close-height: $btn-close-width;
$btn-close-padding-x: .25em;
$btn-close-padding-y: $btn-close-padding-x;
$btn-close-color: $black;
$btn-close-bg: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='#{$btn-close-color}'><path d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/></svg>");
$btn-close-bg-dark: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='#{$white}'><path d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/></svg>");
$btn-close-focus-shadow: none;
$btn-close-opacity: .5;
$btn-close-hover-opacity: .75;
$btn-close-focus-opacity: 1;
$btn-close-disabled-opacity: .25;
$btn-close-white-filter: invert(1) grayscale(100%) brightness(200%);
// scss-docs-end close-variables


// Offcanvas

// scss-docs-start offcanvas-variables
$offcanvas-padding-y: $modal-inner-padding;
$offcanvas-padding-x: $modal-inner-padding;
$offcanvas-horizontal-width: 400px;
$offcanvas-vertical-height: 30vh;
$offcanvas-transition-duration: .3s;
$offcanvas-border-color: $modal-content-border-color;
$offcanvas-border-width: $modal-content-border-width;
$offcanvas-title-line-height: $modal-title-line-height;
$offcanvas-bg-color: $modal-content-bg;
$offcanvas-color: $modal-content-color;
$offcanvas-body-backdrop-color: rgba($modal-backdrop-bg, $modal-backdrop-opacity);
$offcanvas-box-shadow: $modal-content-box-shadow-xs;
// scss-docs-end offcanvas-variables

// Code

$code-font-size: 87.5%;
$code-color: $pink;

$kbd-padding-y: .2rem;
$kbd-padding-x: .4rem;
$kbd-font-size: $code-font-size;
$kbd-color: $white;
$kbd-bg: $gray-900;

$pre-color: $gray-900;