.skeleton-loader-background {
    width: 100%;
    height: 15px;
    display: block;
    background: linear-gradient(
        to right,
        rgba(255, 255, 255, 0),
        rgba(255, 255, 255, 0.5) 50%,
        rgba(255, 255, 255, 0) 80%
    ),
    rgba(211, 211, 211, 0.3);
    background-repeat: repeat-y;
    background-size: 50px 500px;
    background-position: 0 0;
    animation: shine 1s infinite;
    border-radius: 4px;
}

@keyframes shine {	
    to {
      background-position: 100% 0, /* move highlight to right */ 0 0;
    }
}