.search-icon{
    position:absolute;
    top:13px;
    left:10px;
    margin-right:1rem;
    display: inline-block;
}
.search-bar{
    min-width:230px;
}

.u-padding-left{
    padding-left:2rem !important;
    
}