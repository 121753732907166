//
// _helper.scss
//

.font-size-10 {
    font-size: 10px !important;
}

.font-size-11 {
    font-size: 11px !important;
}

.font-size-12 {
    font-size: 12px !important;
}

.font-size-13 {
    font-size: 13px !important;
}

.font-size-14 {
    font-size: 14px !important;
}

.font-size-15 {
    font-size: 15px !important;
}

.font-size-16 {
    font-size: 16px !important;
}

.font-size-17 {
    font-size: 17px !important;
}

.font-size-18 {
    font-size: 18px !important;
}

.font-size-20 {
    font-size: 20px !important;
}

.font-size-22 {
    font-size: 22px !important;
}

.font-size-24 {
    font-size: 24px !important;
}

// Font weight help class

.fw-medium {
    font-weight: $font-weight-medium;
}

.fw-semibold {
    font-weight: $font-weight-semibold;
}


// Icons Sizes
.icon-xs {
    height: 14px;
    width: 14px;
}

.icon-sm {
    height: 16px;
    width: 16px;
}

.icon-md {
    height: 18px;
    width: 18px;
}

.icon-lg {
    height: 20px;
    width: 20px;
}

.icon-xl {
    height: 22px;
    width: 22px;
}


// Card Height 100
.card-h-100 {
    height: calc(100% - #{$grid-gutter-width});
}


// Social

.social-list-item {
    height: 2rem;
    width: 2rem;
    line-height: calc(2rem - 4px);
    display: block;
    border: 2px solid $gray-500;
    border-radius: 50%;
    color: $gray-500;
    text-align: center;
    transition: all 0.4s;

    &:hover {
        color: $gray-600;
        background-color: $gray-200;
    }
}

.w-xs {
    min-width: 80px;
}

.w-sm {
    min-width: 95px;
}

.w-md {
    min-width: 110px;
}

.w-lg {
    min-width: 140px;
}

.w-xl {
    min-width: 160px;
}

// bg overlay
.bg-overlay{
    position: absolute;
    height: 100%;
    width: 100%;
    right: 0;
    bottom: 0;
    left: 0;
    top: 0;
    opacity: 0.7;
    background-color: $black;
}

// alert

.alert-dismissible {
    .btn-close {
        font-size: 10px;
        padding: $alert-padding-y * 1.4 $alert-padding-x;
        background: transparent escape-svg($btn-close-bg) center / $btn-close-width auto no-repeat;
    }
}


body[data-layout-mode="dark"] {
    
    .btn-close {
        background: transparent escape-svg($btn-close-bg-dark) center / $btn-close-width auto no-repeat;
    }

    // border
    .border-light{
        border-color: $gray-dark-300 !important;
    }

    .border-bottom{
        border-bottom: 1px solid $gray-dark-300 !important;
    }

    .border-top,
    .border,
    .list-group-item {
        border-color: $gray-dark-300 !important;
    }

    @each $color, $value in $theme-colors {
        .border-#{$color} {
            border-color: $value !important;
        }
    }

    // text colors
    .text-dark{
        color: $gray-dark-800 !important;
    }

    .text-muted{
        color: $gray-dark-400 !important;
    }

    .text-body{
        color: $gray-dark-500 !important;
    }

    // List item
    .list-group-item {
        background-color: $gray-dark-200;
        color: $gray-dark-500;
    }

    // img thumbnail

    .img-thumbnail{
        background-color: lighten($gray-dark-200,2.5%);
        border-color: $gray-dark-300;
    }

    // popover-header
    .popover-header{
        color: $gray-dark-200;
    }

}